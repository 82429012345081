<template>
  <div class="">
    <trac-loading v-if="isLoading" />
    <trac-validation-observer slim v-slot="{ handleSubmit }">
      <form class="md:ml-12 mb-6 mr-4 md:mr-0" @submit.prevent="handleSubmit(sendForm)">
        <trac-back-button v-if="createCustomerOnTheFly">{{
          createCustomerOnTheFly.origin.name
        }}</trac-back-button>
        <trac-back-button v-else>{{
          updateCustomerFlag ? "Customer Details" : "Customers"
        }}</trac-back-button>
        <div class="flex-col mt-12 mb-8">
          <h2>Customers</h2>
          <h3 class="font-bold mt-4 text-2xl">Add Customers</h3>
        </div>
        <div class="bg-white px-8 py-8 rounded-lg overflow-hidden big-shadow">
          <trac-custom-header>
            <template slot="leading">Contact</template>
            <template slot="trailing"> Details</template>
          </trac-custom-header>
          <div class="grid md:flex flex-row">
            <div class="flex-column md:w-6/12">
              <trac-validation-provider name="first name" rules="required" v-slot="{ errors }">
                <trac-input data-test="first-name-field" v-model="customer.first_name" placeholder="First Name"
                  class="md:w-8/12 mt-4" />
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
              <!-- <trac-dropdown-exteneded
              :neededProperty="''"
              :selector="moment(new Date()).format('DD-MM')"
              :inputDate="new Date()"
              :customDropdown="true"
              class=""
              @selectDate="
                createMilestone.due_date = new Date($event).toISOString()
              "
            ></trac-dropdown-exteneded> -->
            </div>
            <div class="flex-column md:w-6/12">
              <trac-input data-test="last-name-field" v-model="customer.last_name" placeholder="Last Name"
                class="md:w-8/12 mt-4" />
            </div>
          </div>
          <div class="grid md:flex flex-row mt-4 md:mt-12">
            <div class="flex-column md:w-6/12">

              <trac-validation-provider name="email" rules="email" v-slot="{ errors }">
                <trac-input data-test="customer-email-field" v-model="customer.email" placeholder="Email"
                  class="md:w-8/12 mt-4" />
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>


            </div>
            <div class="flex-column md:w-6/12">
              <trac-validation-provider name="phone number" rules="onlynumbers|exactLength:11" v-slot="{ errors }">
                <trac-input data-test="phone-number-field" v-model="customer.phone" placeholder="Phone Number"
                  class="md:w-8/12 mt-4" />
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
          </div>
        </div>
        <div class="bg-white px-8 py-8 mt-5 rounded-lg overflow-hidden big-shadow">
          <trac-custom-header>
            <template slot="leading">Additional</template>
            <template slot="trailing"> Information</template>
          </trac-custom-header>
          <div class="mt-12">
            <div class="w-full">
              <trac-validation-provider name="customer birthday" rules="customerbirthday" v-slot="{ errors }">
                <trac-input data-test="date-field" v-model="customer.day_month" placeholder="DD-MM"
                  class="text-sm md:w-8/12 mt-4">
                </trac-input>
                <trac-input-error v-if="errors[0]">{{
                  errors[0]
                }}</trac-input-error>
              </trac-validation-provider>
            </div>
          </div>
          <div class="mt-4 md:mt-12">
            <div class="w-full">
              <trac-textarea data-test="noted-field" placeholder="Notes" class="md:w-8/12 mt-4"
                v-model="customer.notes" />
            </div>
          </div>
        </div>
        <div class="bg-white px-8 py-8 mt-5 rounded-lg overflow-hidden big-shadow">
          <trac-custom-header>
            <template slot="leading">Customer</template>
            <template slot="trailing"> Address</template>
          </trac-custom-header>
          <div class="grid md:flex flex-row">
            <div class="flex-column md:w-6/12">
              <trac-input data-test="street-field" v-model="customer.address.street" placeholder="Street"
                class="md:w-8/12 mt-4" />
            </div>
            <div class="flex-column md:w-6/12">
              <trac-input data-test="city-field" v-model="customer.address.city" placeholder="City"
                class="md:w-8/12 mt-4" />
            </div>
          </div>
          <div class="grid md:flex flex-row mt-4 md:mt-12">
            <div class="flex-column md:w-6/12">
              <trac-input data-test="state-field" v-model="customer.address.state" placeholder="State"
                class="md:w-8/12 mt-4" />
            </div>
            <div class="flex-column md:w-6/12">
              <trac-input data-test="country-field" v-model="customer.address.country" placeholder="Country"
                class="md:w-8/12 mt-4" />
            </div>
          </div>
        </div>
        <div class="mt-10 mx-4 md:mx-0 md:flex flex-row justify-end">
          <div class="">
            <trac-button data-test="save-customer-button" buttonType="submit" class="w-full">{{
              updateCustomerFlag ? "UPDATE CUSTOMER" : "SAVE CUSTOMER"
            }}</trac-button>
          </div>
        </div>
      </form>
    </trac-validation-observer>
  </div>
</template>

<script>
import {
  DELETE_LOCAL_DB_DATA,
  GET_LOCAL_DB_DATA,
  GET_USER_BUSINESS_DATA,
  GET_USER_BUSINESS_ID,
  SAVE_LOCAL_DB_DATA,
} from "../../browser-db-config/localStorage";
import moment from "moment";
import { eventBus } from "./../../main";
import { GENERATE_RANDOM_ID } from "../../browser-db-config/utilityMethods";

export default {
  data() {
    return {
      moment,
      isLoading: false,
      updateCustomerFlag: false,
      createCustomerOnTheFly: null,
      customer: {
        first_name: "",
        last_name: "",
        email: "",
        phone: "",
        notes: "",
        day_month: "",
        address: {
          street: "",
          city: "",
          state: "",
          country: "",
        },
      },
    };
  },
  created() {
    this.createCustomerOnTheFly = GET_LOCAL_DB_DATA(
      "create-customer-on-the-fly"
    );
    this.updateCustomerFlag = GET_LOCAL_DB_DATA("customer-to-update");
    if (this.updateCustomerFlag) {
      this.customer = GET_LOCAL_DB_DATA("customer-to-update");
    }
  },
  destroyed() {
    DELETE_LOCAL_DB_DATA("customer-to-update");
    DELETE_LOCAL_DB_DATA("create-customer-on-the-fly");
    this.$store.dispatch("REFRESH_ALL_CUSTOMERS");
  },
  methods: {
    async sendForm() {
      this.isLoading = true;
      if (this.updateCustomerFlag) {
        // console.log('Update Ran');
        await this.updateCustomer();
      } else {
        // await this.createCustomer();
        this.createCustomerWithOfflineMgr();
      }
      this.isLoading = false;
    },
    createCustomerWithOfflineMgr() {
      console.log(this.isOnline);
      if (this.isOnline) {
        this.createCustomer();
      } else {
        const offlinePayload = {
          ...this.customer,
          cust_offline_id: GENERATE_RANDOM_ID(),
          company_name: GET_USER_BUSINESS_DATA().name,
          business: GET_USER_BUSINESS_ID(),
        };

        this.$GlobalOfflineManager.insert("customers_unsynced", offlinePayload);

        eventBus.$emit("trac-alert", {
          message: "Customer created offline.",
        });
        this.requestSync("customers").then(() => {
          this.$router.back();
        })
      }
    },
    updateCustomerOfflineCopy() {
      // const offlinePayload = {
      //   ...this.customer,
      //   cust_offline_id: GENERATE_RANDOM_ID(),
      //   company_name: GET_USER_BUSINESS_DATA().name,
      //   business: GET_USER_BUSINESS_ID(),
      // };
      // update local copy:
      return this.$GlobalOfflineManager.db.collection('customers').doc({ _id: this.customer._id }).update(this.customer)
    },
    async createCustomer() {
      await this.$store.dispatch("CREATE_CUSTOMER", this.customer);
      // if (this.createCustomerOnTheFly) {
      // } else {
      //   await this.$store.dispatch("CREATE_OFFLINE_CUSTOMER", this.customer);
      // }
      const res = this.$store.getters["GET_CUSTOMER_ACTION_RES"];

      if (res.status) {
        //   Add customers and route to customers
        // alert(res.message || "Customer creation successful.");

        const resData = res.data;

        if (this.createCustomerOnTheFly) {
          if (this.createCustomerOnTheFly.origin.name === "POS") {
            this.createCustomerOnTheFly.sale.customer = resData.customer;
            this.createCustomerOnTheFly.sale.customer.customerFullName =
              resData.customer.first_name + " " + resData.customer.last_name;
            SAVE_LOCAL_DB_DATA(
              "sale-details",
              this.createCustomerOnTheFly.sale
            );
          } else if (
            this.createCustomerOnTheFly.origin.name === "Add Invoice"
          ) {
            this.createCustomerOnTheFly.invoice.client = resData.customer._id;
            this.createCustomerOnTheFly.invoice.customer = resData.customer;
            SAVE_LOCAL_DB_DATA(
              "invoice-details",
              this.createCustomerOnTheFly.invoice
            );
          }
        }
        eventBus.$emit("trac-alert", {
          message: "Customer creation successful",
        });
        this.requestSync("customers").then(() => {
          this.$router.back();
        })
      } else {
        // alert(res.message || "Error: Network error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
      }
    },
    async updateCustomer() {
      await this.$store.dispatch("UPDATE_CUSTOMER", this.customer);
      const res = this.$store.getters["GET_CUSTOMER_ACTION_RES"];

      if (res.status) {
        //   Add customers and route to customers
        // alert(res.message || "Customer update successful.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Customer update successful.",
        });
        SAVE_LOCAL_DB_DATA("customer-details", res.data.customer);

        // update the local copy
        this.updateCustomerOfflineCopy().then(() => {
          return this.requestSync("customers")
        }).then(() => {
          this.$router.replace({ name: 'Customers' });
        }).catch((err) => {
          console.error('Error while updating local copy: ', err)
        })
      } else {
        // alert(res.message || "Error: Network error occured.");
        eventBus.$emit("trac-alert", {
          message: res.message || "Error: Network error occured.",
        });
      }
    },
  },
};
</script>

<style scoped></style>
